export default {
  props: {
    popupConfig: Object,
    error: Object,
  },
  data: () => ({
    formData: {},
  }),
  methods: {
    focus(selector) {
      $(this.$el).find(selector).focus()
    },
  },
  computed: {
    apiErrorStatus() {
      if(!this.error) return null
      return this.error.status
    },
    apiError() {
      if(!this.error) return {}
      return this.error.data
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.formData))
      },
    }
  },
}
