<template>
  <div>
    <v-text-field
      dense
      outlined
      counter
      autofocus
      @keypress.enter="focus('.email input')"
      :label="$t('data.person_name')"
      :error="!!apiError.name"
      :error-messages="apiError.name"
      maxlength="100"
      :rules="[minTextRule]"
      v-model="formData.name"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      counter
      class="email"
      @keypress.enter="focus('.password input')"
      :label="$t('data.email')"
      :error="!!apiError.email"
      :error-messages="apiError.email"
      maxlength="100"
      :rules="[emailRule]"
      v-model="formData.email"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      counter
      class="password"
      type="password"
      @keypress.enter="focus('.password_confirm input')"
      :label="$t('data.password')"
      :error="!!apiError.password"
      :error-messages="apiError.password"
      maxlength="100"
      :rules="[minTextRule]"
      v-model="formData.password"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      counter
      type="password"
      class="password_confirm"
      :label="$t('data.password_confirm')"
      :error="!!apiError.password_confirm"
      :error-messages="apiError.password_confirm"
      maxlength="100"
      :rules="[minTextRule, passwordEqual]"
      v-model="formData.password_confirm"
    ></v-text-field>
  </div>
</template>

<script lang="babel" type="text/babel">
import listCreatePopupMixin from '@/components/list/listCreateButton/listCreatePopupMixin'
export default {
  mixins: [listCreatePopupMixin],
  data: () => ({
    formData: {
      name: null,
      email: null,
      password: null,
      password_confirm: null,
    },
  }),
  computed: {
    passwordEqual() {
      return () => this.formData.password == this.formData.password_confirm || this.$t('validate.error.password_not_match')
    },
    emailRule() {
      return value => this.$helper.validator.emailRule(value)
    },
    minTextRule() {
      return value => this.$helper.validator.minTextRule(value, 2)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>